<template>
  <b-modal
      id="acquiringHintModal"
      title="Доступні термінали"
      ok-only
  >
    <p>Для поповнення використовуйте номер особового рахунку: <b>{{ getCashlessTerminalId() }}</b></p>
    <p>Вам доступні наступні термінали:</p>
    <p v-if="availableTerminals"><b>{{ availableTerminals.join(", ") }}</b></p>
  </b-modal>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";
import { useAcquiringProviders } from "@/composition/acquiringProviders/useAcquiringProviders";
import { useBusinessTransactions } from "@/composition/businessTransactions/useBusinessTransactions";

export default {
  name: "AcquiringHintModal",
  components: {
    BModal,
    BButton,
  },
  setup() {
    const { availableTerminals } = useAcquiringProviders();
    const { getCashlessTerminalId } = useBusinessTransactions();

    return {
      availableTerminals,
      getCashlessTerminalId,
    }
  }
};
</script>

<style scoped>

</style>
