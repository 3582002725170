<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-row>
        <b-skeleton class="mx-1" type="button"></b-skeleton>
        <b-skeleton class="mx-1" type="button"></b-skeleton>
      </b-row>
    </template>
    <b-row>
      <b-button
          v-for="provider in providers"
          :key="provider.id"
          :variant="provider.value === value ? 'success' : 'outline-success'"
          class="mx-1"
          @click="setSelectedProvider(provider.value)"
      >
        <img :src="provider.imageUrl" :alt="provider.title" class="provider-icon pr-1">
        <span>{{ provider.title }}</span>
      </b-button>
    </b-row>
  </b-skeleton-wrapper>
</template>

<script>

import { BSkeleton, BSkeletonWrapper, BButton, BRow } from "bootstrap-vue";

export default {
  name: "AcquiringProviders",

  components: {
    BSkeleton,
    BSkeletonWrapper,
    BButton,
    BRow,
  },

  props: {
    providers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    value: {
      type: String,
      default: "",
    },
  },

  emits: ['input'],

  setup(props, {emit}) {

    const setSelectedProvider = (provider) => {
      emit("input", provider)
    }

    return {
      setSelectedProvider,
    }
  }
}
</script>

<style scoped lang="scss">
.provider-icon {
  height: 32px;
}
</style>