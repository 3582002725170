<template>
  <b-modal
      id="acquiringModal"
      title="Поповнення безготівкового рахунку"
      hide-footer
      @hidden="clearForm"
  >
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form
          id="acquiringModal"
          title="Оплата"
          hide-footer
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >

        <validation-provider
            #default="validationContext"
            name="provider"
            :rules="{required: true}"
        >
          <b-form-group
              id="provider"
              label="Оберіть сервіс для оплати"
              label-for="provider"
              :state="getValidationState(validationContext)"
          >
            <AcquiringProviders
              v-model="paymentMethod"
              :providers="acquiringProviders"
              :loading="acquiringProvidersLoading"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
            #default="validationContext"
            name="amount"
            rules="required|min_value:1"
        >

          <b-form-group
              id="amount"
              label="Сума"
              label-for="amount"
              :state="getValidationState(validationContext)"
          >
            <b-form-input
                v-model="amount"
                type="number"
                placeholder="Введіть суму"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

        </validation-provider>

        <validation-provider
            v-show="availableCards.length"
            #default="validationContext"
            name="card"
        >
          <b-form-group
              id="card"
              label="Оберить карту для оплати"
              label-for="card"
              :state="getValidationState(validationContext)"
          >
            <AvailableCards
              v-model="tokenId"
              :cards="availableCards"
            />
          </b-form-group>
        </validation-provider>

        <b-overlay
            :show="paymentContextLoading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
        >
          <b-button
              ref="button"
              :disabled="paymentContextLoading"
              variant="primary"
              @click.prevent="handleSubmit(onSubmit)"
          >
            Сплатити
          </b-button>
        </b-overlay>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import { BForm, BFormGroup, BButton, BFormInvalidFeedback, BModal, BFormInput, BOverlay } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AcquiringProviders from "@/views/apps/acquiring/AcquiringProviders.vue";
import AvailableCards from "@/views/apps/acquiring/AvailableCards.vue";

import {computed, ref, watch} from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, min_value } from "@core/utils/validations/validations";
import {useAcquiringProviders} from "@/composition/acquiringProviders/useAcquiringProviders";

import { dollarsToCents } from "@/helpers/priseConvert";

export default {
  name: "AcquiringModal",

  components: {
    BModal,
    BFormInput,
    BForm,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    AcquiringProviders,
    AvailableCards,
  },

  setup() {

    const {
      acquiringProviders,
      acquiringProvidersLoading,
      cards,
      postPaymentContext,
      paymentContextLoading,
    } = useAcquiringProviders();

    const amount = ref("");
    const paymentMethod = ref("");
    const tokenId = ref("");

    const clearForm = () => {
      amount.value = "";
      paymentMethod.value = ""
      tokenId.value = ""
    };

    const availableCards = computed(() => {
      if (paymentMethod && cards.value) {
        return cards.value[paymentMethod.value]?.items ?? [];
      } else {
        return [];
      }
    });

    const navigateAcquiring = (url) => {
      window.open(url, "_blank");
    };

    const onSubmit = async () => {
      const params = {
        amount: dollarsToCents(+amount.value),
        paymentMethod: paymentMethod.value,
        ...(tokenId.value && { tokenId: tokenId.value })
      };
      const response = await postPaymentContext(params);
      const url = response.data.data.url;
      navigateAcquiring(url);

    };

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(clearForm);

    watch(paymentMethod, () => {
      tokenId.value = "";
    })

    return {
      amount,
      refFormObserver,
      acquiringProviders,
      acquiringProvidersLoading,
      getValidationState,
      resetForm,
      clearForm,
      required,
      min_value,
      navigateAcquiring,
      availableCards,
      paymentMethod,
      tokenId,
      onSubmit,
      paymentContextLoading,
    }
  }
}
</script>